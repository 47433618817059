<template>
  <div id="sign-up">
    <section class="section head">
      <div class="container">
        <h1>
          REGISTER
        </h1>
      </div>
    </section>

    <section class="section">
      <div class="container" id="already-registered">
        <span class="material-icons">label_important</span>
        If you already have a songfile account,
        <a class="forgot-credentials" @click="accountRecovery">click here</a>.
      </div>
      <div class="container accordion-list">
        <div class="item licensing-terms" :class="{ open: step == 1 }">
          <div class="item-header">
            <div class="number" :class="{ done: step > 1 }">
              <span>1</span>
              <md-icon>check</md-icon>
            </div>

            <h4>REVIEW AND ACKNOWLEDGE SONGFILE® LICENSING TERMS</h4>
          </div>

          <div class="item-content">
            <p>
              Please review the sample Songfile® license below. If your order
              includes songs for which HFA can issue a Songfile® license, you
              will receive a copy of the license. The sample license below sets
              forth the licensing terms that will govern the license requested
              by you. In order to complete your licensing request, you must
              acknowledge that you have reviewed and understand the terms of the
              Songfile® license by clicking "ACCEPT" below the sample license.
              The license will not be valid until you have provided all required
              information and payment in full has been made. The terms of the
              license will be governed by the final form available to you upon
              completion of the transaction.
            </p>
            <p>
              Your order may include the transmission of a Notice of Intention
              (NOI). If an NOI is to be transmitted on your behalf, the sample
              Songfile® license terms are separate and distinct from the terms
              of the license obtained from the transmission of an NOI. For more
              details about the terms of the statutory license, please visit the
              <a
                href="https://copyright.gov/title17/92chap1.html#115"
                target="_blank"
              >
                Copyright Office</a
              >. Additional information can be obtained from the Copyright
              Circulars
              <a href="https://copyright.gov/circs/circ73a.pdf" target="_blank">
                73A</a
              >
              and
              <a href="https://copyright.gov/circs/circ73b.pdf" target="_blank">
                73B</a
              >. By clicking “ACCEPT” below, you acknowledge and agree that you
              have reviewed and understand the terms of the statutory license.
            </p>

            <div class="terms" id="licensing-terms">
              <LicenseTerms class="no-pdf" />
            </div>

            <div class="item-footer">
              <md-button
                @click="step = 2"
                :disabled="!scrolledTerms"
                class="md-primary md-raised large-width"
              >
                Accept
              </md-button>

              <span>
                The terms of the Songfile® license will be available to download
                once your license has cleared. If your order requires an NOI, a
                PDF copy will be available to you in the My Orders tab soon
                after the NOI is transmitted.
              </span>
            </div>
          </div>
        </div>

        <div class="item signup-form" :class="{ open: step == 2 }">
          <div class="item-header">
            <div class="number" :class="{ done: step > 2 }">
              <span>2</span>
              <md-icon>check</md-icon>
            </div>

            <h4>CREATE YOUR ACCOUNT</h4>
          </div>

          <div class="item-content">
            <p>
              Please enter your information to set up your Songfile user
              account.
              <a href="javascript:;">Privacy Policy</a>
            </p>

            <AccountForm :register="true" @created="step = 3" />
          </div>
        </div>

        <div class="item registration-complete" :class="{ open: step == 3 }">
          <div class="item-header">
            <div class="number" :class="{ done: step > 3 }">
              <span>3</span>
              <md-icon>check</md-icon>
            </div>

            <h4>YOUR REGISTRATION IS COMPLETE</h4>
          </div>

          <div class="item-content">
            <p>
              You will receive an email confirmation with your registration
              information. It will also contain a link to confirm your email
              address and activate your account. Once that is complete you can
              begin licensing songs.
            </p>

            <md-button class="md-raised md-primary" :to="{ name: 'home' }">
              <md-icon>arrow_left</md-icon>
              Back To Home
            </md-button>

            <md-button
              class="md-raised md-primary"
              :to="{ name: 'public-search' }"
            >
              Search Songs
            </md-button>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import AccountForm from "@/components/AccountForm.vue";
import LicenseTerms from "@/components/LicenseTerms.vue";

export default {
  name: "SignUp",
  components: {
    AccountForm,
    LicenseTerms
  },
  methods: {
    accountRecovery: function() {
      this.$router.push({ name: "account-recovery" });
    }
  },
  metaInfo: function() {
    return {
      title: " - Register",
      meta: [
        {
          name: "description",
          content: "Create your Songfile account."
        }
      ],
      link: [
        {
          vmid: "canonical",
          rel: "canonical",
          href: this.$store.getters["canonical"]()
        }
      ]
    };
  },
  data: function() {
    return {
      step: 1,
      scrolledTerms: false
    };
  },
  mounted: function() {
    if (this.$store.getters["auth/isLoggedIn"]) {
      this.$router.push({ name: "my-profile" });
    }

    const terms = document.getElementById("licensing-terms");
    terms.addEventListener("scroll", () => {
      if (
        Math.ceil(terms.scrollHeight - terms.scrollTop) <=
        terms.clientHeight + 100
      ) {
        this.scrolledTerms = true;
      }
    });
  }
};
</script>

<style lang="scss">
#sign-up {
  .registration-complete {
    .md-button {
      width: 150px;
    }
  }
}
#already-registered {
  margin-bottom: 30px;
  font-size: 20px;
  .material-icons {
    vertical-align: sub;
  }
}
</style>
