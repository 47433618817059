<template>
  <div id="license-terms">
    <p>
      <strong>The Harry Fox Agency LLC</strong><br />
      40 Wall Street, 6th floor<br />
      New York, NY 10005<br />
      Email:
      <a href="mailto:clientservices@harryfox.com">
        clientservices@harryfox.com
      </a>
    </p>

    <p class="align-center">
      <strong>Limited Quantity License Terms</strong>
    </p>

    <p class="align-right bot-border">
      <span class="name-address">
        <span class="to">TO:</span>
        [NAME]<br />
        [ADRESS]<br />
        [CITY, STATE, ZIP]
      </span>
      <br />
      LICENSE NO.: [HFA LICENSE NO.]<br />
      LICENSE DATE: [MONTH/DAY/YEAR]
    </p>

    <p>
      <strong>
        Refer to the provisions hereof reproduced in (E) varying terms of
        compulsory license provision of Copyright Act. The following is
        supplementary thereto:
      </strong>
    </p>

    <ul class="main">
      <li>
        <ul>
          <li>SONG CODE: [HFA SONG IDENTIFICATION NO.]</li>
          <li>TITLE: [SONG TITLE]</li>
          <li>WRITERS: [NAME(S)]</li>
        </ul>
      </li>

      <li>
        <ul>
          <li>
            INCOME PARTICIPANTS:
          </li>
          <li>[PUBLISHER NAME(S)]</li>
        </ul>
      </li>

      <li>
        <ul>
          <li>RECORD NO.: [YOUR RECORD NUMBER]</li>
          <li>
            ISRC NO.: [INTERNATIONAL STANDARD RECORDING CODE FOR YOUR SOUND
            RECORDING]
          </li>
          <li>ARTIST: [NAME]</li>
          <li>ROYALTY FEE (BASED ON STATUTORY RATE): [YOUR ROYALTY FEE]</li>
          <li>PLAYING TIME: [MINUTES AND SECONDS]</li>
          <li>
            QUANTITY OF LICENSED: [NUMBER OF UNITS YOU EXPECT TO
            MAKE/DISTRIBUTE]
          </li>
          <li>CONFIGURATION: [YOUR CHOSEN CONFIGURATION]</li>
        </ul>
      </li>

      <li>
        <ul>
          <li>
            <p>ADDITIONAL PROVISIONS:</p>

            <p>
              Licensee is not required to countersign this limited quantity
              license.
            </p>

            <p>
              This license and all authority to make and distribute phonorecords
              and digital phonorecord deliveries (as defined in Section 115 of
              the Copyright Act), including, without limitation, ringtones,
              permanent downloads, and interactive streams, (“DPDs”) is limited
              to the making and distribution of no more than the quantity of
              phonorecords or DPDs, as applicable, stated in (C) supra.
            </p>

            <p>
              For physical phonorecords (identified in (C) as either CD, CS, or
              LP), the authority hereunder is limited to the manufacture and
              distribution of no more than the quantity of phonorecords stated
              in (C) supra solely in the United States and its territories and
              possessions, and not elsewhere.
            </p>

            <span class="html2pdf__page-break" />

            <p>
              For DPDs (identified in (C) as IS, PDD, or RT), this license and
              all authority to make and distribute DPDs hereunder shall expire
              one (1) year from the license date shown above. The authority
              hereunder is limited to the making of a copy of a sound recording
              of the musical work on a computer file server located in the
              United States, its territories or possessions, solely for the
              purpose of distributing such DPDs.
            </p>

            <p>
              For Ringtones (identified in (C) as RT), the authority hereunder
              is limited solely to the making and distributing of ringtones and
              is not applicable to the use of ringbacks. Ringbacks substitute an
              excerpt of a particular master sound recording of a composition
              for the sound a caller normally hears while waiting for the person
              called to answer the phone. Ringbacks are not within the scope of
              this license.
            </p>

            <p>
              Credit: In regard to all phonorecords or DPDs, as applicable,
              distributed hereunder, you shall, to the extent feasible, include
              writer/publisher credit in the form of the names of the writer(s)
              and publisher(s) of the musical work embodied in the phonorecord,
              in,
            </p>

            <ul>
              <li>
                (a) for DPDs, any graphics and imagery displayed in connection
                with the playback of such DPDs; or
              </li>

              <li>
                (b) for physical phonorecords, in the label copy of all such
                phonorecords, or on the permanent containers of all such
                phonorecords.
              </li>
            </ul>

            <p>
              The ISRC number for the relevant recording of the musical work
              licensed hereunder (or other applicable identification number)
              shall be included in the metadata of the DPD or phonorecord and
              must be provided to us if such ISRC or identification number
              exists.
            </p>
          </li>
        </ul>
      </li>

      <li>
        <ul>
          <li>
            <p>GENERAL VARIATIONS OF COMPULSORY LICENSE PROVISION:</p>

            <ul class="numerical">
              <li>
                <ul>
                  <li>
                    You have advised us, in our capacity as agent for the
                    publisher(s) referred to in (B) supra that you wish to
                    obtain a compulsory license to make and to distribute (as
                    identified in (C) Configuration):

                    <ul>
                      <li>
                        (a) DPDs either in the form of
                        <strong>Interactive Streams</strong> (Configuration Code
                        “[<strong>IS</strong>]”),
                        <strong>Permanent Downloads</strong> (Configuration Code
                        “[<strong>PDD</strong>]”, herein referred to as “PDDs”),
                        or <strong>Ringtones</strong> (Configuration Code
                        “[<strong>RT</strong>]”) (each as defined in Section 115
                        of the Copyright Act and regulations promulgated
                        thereunder, as such may be amended or superseded from
                        time to time) of the musical work referred to in (A)
                        supra under the compulsory license provision of Section
                        115 of the Copyright Act. The provisions hereof vary the
                        terms of the compulsory license provision of the
                        copyright act applicable to Interactive Streams, PDDs,
                        or Ringtones. Upon issuance of this license, you shall
                        have all the rights which are granted to, and all the
                        obligations which are imposed upon, users of said
                        musical work under the compulsory license provision of
                        the Copyright Act, after phonorecords of the copyrighted
                        work have been distributed to the public in the United
                        States under the authority of the copyright owner by
                        another person, except
                        <span class="html2pdf__page-break" />that with respect
                        to Interactive Streams, PDDs, or Ringtones thereof (i)
                        made and distributed hereunder, and (ii) in
                        consideration of the limited quantity of the DPDs
                        authorized hereunder and limited license term of one (1)
                        year, and, (iii) upon your requesting this license, your
                        nonrefundable payment to agent for and on behalf of said
                        publisher(s) of the full royalty fee set forth in (C),
                        supra, you need not otherwise pay royalties or account
                        to us as agent. This license shall automatically be
                        deemed null and void and of no effect if agent fails to
                        receive timely, valid payment of all fees due with
                        respect to this license.
                      </li>

                      <li>
                        (b)
                        <strong>physical phonorecords</strong> (Configuration
                        Code “[<strong>CD/CS/LP</strong>]”) of the copyrighted
                        work referred to in (A) supra under the compulsory
                        license provisions of Section 115 of the Copyright Act.
                        Upon issuance of this license, you shall have all the
                        rights which are granted to, and all the obligations
                        which are imposed upon, users of said copyrighted work
                        under the compulsory license provision of the Copyright
                        Act, after phonorecords of the copyrighted works have
                        been distributed to the public in the United States
                        under the authority of the copyright owner by another
                        person, except that with respect to phonorecords thereof
                        (i) made and distributed hereunder and (ii) in
                        consideration of the limited quantity of phonorecords
                        authorized hereunder and, (iii) upon your requesting
                        this license, your nonrefundable payment to agent for
                        and on behalf of said publisher(s) of the full royalty
                        fee set forth in (C) supra, you need not otherwise pay
                        royalties or account to us as agent. This license shall
                        automatically be deemed null and void and of no effect
                        if agent fails to receive timely, valid payment of all
                        fees due with respect to this license.
                      </li>
                    </ul>
                  </li>
                </ul>
              </li>

              <li>
                <ul>
                  <li>
                    This compulsory license covers and is limited to one
                    particular recording of said copyrighted work as performed
                    by the artist and on the use identified in (C) and (D) supra
                    and this compulsory license does not supersede nor in any
                    way affect any prior agreements now in effect respecting
                    phonorecords of said copyrighted work.
                  </li>
                </ul>
              </li>

              <li>
                <ul>
                  <li>
                    You need not serve or file the notice of intention to obtain
                    a compulsory license required by the Copyright Act.
                  </li>
                </ul>
              </li>

              <li>
                <ul>
                  <li>Additional provisions are reproduced under (D) supra.</li>
                </ul>
              </li>
            </ul>
          </li>
        </ul>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "LicenseTerms"
};
</script>

<style lang="scss">
#license-terms {
  * {
    font-family: "Times New Roman";
  }

  &.no-pdf {
    ul.main {
      padding-left: 20px;

      li {
        > ul {
          padding-left: 0;
        }

        ul.numerical {
          padding-left: 25px;

          > li {
            ul {
              padding-left: 10px;
            }
          }
        }
      }
    }
  }

  ul.main {
    list-style: upper-alpha;

    li {
      > ul {
        li {
          margin-bottom: 0;
        }
      }

      ul.numerical {
        list-style: decimal;

        > li {
          margin-bottom: 20px;

          ul {
            li {
              margin-top: 10px;
            }
          }
        }
      }
    }
  }
  .name-address {
    float: left;
    text-align: left;
    padding-left: 35px;
    position: relative;

    .to {
      display: block;
      position: absolute;
      left: 0;
    }
  }

  .align-center {
    text-align: center;
  }
  .align-right {
    text-align: right;
  }
  .bot-border {
    border-bottom: 1px solid;
  }
}
</style>
